import React from "react";
import { cn } from "@/lib/utils";

interface SectionTitleProps {
  children: any;
  className?: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        "flex w-full items-center justify-center",
        className,
      )}
    >
      <span className="text-[#171A1F] font-bold text-[24px] md:text-[48px] leading-[36px] md:leading-[68px]">
        {children}
      </span>
    </div>
  );
};

