import React from "react";
import { SectionTitle } from "@/components/ui/sectionTitle";
import { SENSOR_LIST } from "@/constants";
import { ProductDescription } from "../content/02_Product";

interface ComponentProps {
  product: any;
}

export const Product: React.FC<ComponentProps> = ({ product }) => {
  return (
    <>
      <div id="sensor"></div>
      <div className="py-[75px] md:py-[120px] text-center p-5 max-w-screen-xl m-auto">
        {product.featured && (<p>Rocknitive IoT - {product.name}</p>)}
        <SectionTitle className="inline-block mb-[28px]">{product.title}</SectionTitle>

        <div className="flex flex-col text-left text-[20px] leading-[40px] mb-8">
          <p>
            {product.description}
          </p>
          <ProductDescription product={product} />
        </div>
        <div className="flex flex-col md:flex-row text-left space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex w-full md:w-1/3 bg-[#fddff1] h-[280px] md:h-[320px] rounded-[20px] overflow-hidden relative">
            <img src={product.image} alt="Product" className="relative w-full h-full object-cover object-center" />
          </div>
          <div className="flex flex-wrap w-full md:w-2/3 h-[550px] md:h-[320px] rounded-[20px] px-[12px] md:py-[32px] overflow-hidden relative border">
            {product.sensors.map((sensorId: any, index: number) => {
              const sensor = SENSOR_LIST.find((s) => s.id === sensorId);

              if (!sensor) {
                return null;
              }

              return (
                <div key={index} className="w-1/2 md:w-1/4 my-4">
                  <img src={sensor.image} alt={sensor.name} className="w-[64px] h-[64px] m-auto" />
                  <h3 className="text-lg mt-2 text-center">{sensor.name}</h3>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col md:flex-row text-left space-y-4 md:space-y-0 md:space-x-4">
          
        </div>
      </div>
    </>
  );
};
