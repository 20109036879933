import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { AppHeader, AppHeaderDesktop } from "@/components";
import { useAppHeaderHeight } from "@/hooks";
import { UIContext } from "@/context";
import { cn } from "@/lib/utils";

// Define the links (Desktop)
const LINKS = [
  { name: "Device", path: "/#/device" },
  { name: "Sensors", path: "/#/sensors" },
  { name: "Products", path: "/#/products" },
  { name: "Data Hub", path: "/#/datahub" },
];

export const LayoutApp: React.FC = () => {
  const { appHeaderHeight } = useAppHeaderHeight();
  const { isDeviceMobile }: any = useContext(UIContext);
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('scrollToTopAnchor')?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, [pathname]);

  return (
    <>
      <div className="relative flex h-dvh w-screen flex-col bg-background-primary">
        {/* Outlet and content should scroll within this container */}
        <div
          className="flex flex-1 flex-col overflow-y-auto"
          style={{ paddingTop: appHeaderHeight }}
        >
          {/* App Header (conditionally rendered & hidden via tailwind classes depending on device) */}
          <AppHeader
            links={LINKS.slice(0, 2)}
            className={cn(!isDeviceMobile && "md:hidden")}
          />

          {/** App Header for Desktop (hidden via tailwind classes for mobile devicess) */}
          <AppHeaderDesktop
            links={LINKS}
            className={cn(isDeviceMobile ? "hidden" : "max-md:hidden")}
          />

          <div id="scrollToTopAnchor" className="relative top-[-70px] left-0"/>
          <Outlet key={"outlet"} />
        </div>
      </div>
    </>
  );
};
