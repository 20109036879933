import React from "react";

interface ComponentProps {
  product: any;
}

export const ProductDescription: React.FC<ComponentProps> = ({ product }: any) => {
  return content[product.id] ?? null;
}

const content: any = {
  'air-pollution': (
    <div className="pb-[24px] max-w-screen-xl m-auto">
      <div className="text-left text-[20px] leading-[40px]">
        <h2 className="mt-8 text-[28px] leading-[56px]"><strong>Control Pollution, Protect Public Health</strong></h2>
        <p>
          Monitoring air quality is essential for cities, governments, factories, and industries to reduce pollution levels, comply with regulations, and safeguard public health.
        </p>

        <h2 className="mt-8 text-[28px] leading-[56px]"><strong>What Pollutants Are Monitored?</strong></h2>
        <p>
          Our AI-powered environmental sensors detect key pollutants identified by WHO as harmful to public health:
          <ul className="list-disc list-inside">
            <li><strong>Particulate Matter (PM2.5 and PM10):</strong> These tiny particles penetrate the lungs and bloodstream, leading to respiratory and cardiovascular problems.</li>
            <li><strong>Nitrogen Dioxide (NO2):</strong> A common pollutant from industrial activities and transport, linked to respiratory diseases.</li>
            <li><strong>Sulfur Dioxide (SO2):</strong> Emitted by fossil fuel combustion, affecting respiratory systems and contributing to acid rain.</li>
            <li><strong>Ozone (O3) and Carbon Monoxide (CO):</strong> Harmful gases that impact lung function and overall health.</li>
          </ul>
        </p>

        <h2 className="mt-8 text-[28px] leading-[56px]"><strong>Why Choose Our AI-Powered Sensors?</strong></h2>
        <p>
          Our sensors provide continuous, accurate monitoring of these critical pollutants, allowing you to:
          <ul className="list-disc list-inside">
            <li><strong>Improve Air Quality:</strong> Identify pollution sources and take action to reduce emissions.</li>
            <li><strong>Meet Health Guidelines:</strong> Stay compliant with WHO and local air quality standards.</li>
            <li><strong>Protect Public Health:</strong> Reduce the risk of heart disease, stroke, lung cancer, and respiratory conditions.</li>
          </ul>
        </p>
      </div>
    </div>
  ),
};