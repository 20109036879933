const APP_VERSION = 1;

const LOGO = "/logo.svg";

// legal
const LEGAL_TERMS =
  import.meta.env.VITE_LEGAL_TERMS || "";
const LEGAL_PRIVACY =
  import.meta.env.VITE_LEGAL_PRIVACY || "https://rocknitive.com/datenschutz.html";
  const LEGAL_IMPRINT =
  import.meta.env.VITE_LEGAL_IMPRINT || "https://rocknitive.com/impressum.html";

const SENSOR_LIST = [
  {
    id: 'nb-iot',
    name: 'NB-IoT / GSM',
    description: 'The NB-IoT / GSM module is used for communication with the cloud.',
    image: '/images/sensors/gsm.svg',
  },
  {
    id: 'gps',
    name: 'GPS',
    description: 'The GPS module is used for position determination.',
    image: '/images/sensors/gps.svg',
  },
  {
    id: 'battery',
    name: 'Battery',
    description: 'The battery is used for power supply.',
    image: '/images/sensors/battery.svg',
  },
  {
    id: 'vibration',
    name: 'Vibration',
    description: 'The vibration sensor is used for detecting vibrations.',
    image: '/images/sensors/vibration.svg',
  },
  {
    id: 'temperature',
    name: 'Temperature',
    description: 'The temperature sensor is used for temperature measurement.',
    image: '/images/sensors/temperature.svg',
  },
  {
    id: 'humidity',
    name: 'Humidity',
    description: 'The humidity sensor is used for humidity measurement.',
    image: '/images/sensors/humidity.svg',
  },
  {
    id: 'microparticles',
    name: 'Microparticles',
    description: 'The microparticles sensor is used for detecting microparticles.',
    image: '/images/sensors/microparticles.svg',
  },
  {
    id: 'acceleration',
    name: 'Acceleration',
    description: 'The acceleration sensor is used for detecting accelerations.',
    image: '/images/sensors/acceleration.svg',
  },
  {
    id: 'geofencing',
    name: 'Geofencing',
    description: '',
    image: '/images/sensors/geofencing.svg',
  },
  {
    id: 'meshnet',
    name: 'Meshnet',
    description: '',
    image: '/images/sensors/meshnet.svg',
  },
  {
    id: 'water',
    name: 'Water',
    description: '',
    image: '/images/sensors/water.svg',
  },
  {
    id: 'powerflow',
    name: 'Power flow',
    description: '',
    image: '/images/sensors/powerflow.svg',
  },
  {
    id: 'vandalism',
    name: 'Unauthorized access',
    description: '',
    image: '/images/sensors/vandalism.svg',
  },
];

const PRODUCT_LIST = [
  {
    id: "environmental",
    name: "Environmental Sensor",
    title: "Smart Battery-Powered Climate Monitoring",
    abstract: "Ensure optimal environmental conditions and track your assets seamlessly with the sensor.",
    description: "Ensure optimal environmental conditions and track your assets seamlessly with the Environmental Sensor, a powerful battery-powered IoT device built for long-lasting and reliable monitoring. Equipped with advanced sensors and designed for mobile use, this device is perfect for a wide range of applications from critical infrastructure to vehicles. Stay informed with real-time tracking of critical environmental factors, including Humidity levels (high/low), Temperature fluctuations, Microparticles in the air (e.g., dust, pollen), Failures of air conditioning systems and filters. Powered by a durable battery with a lifespan of up to 24 months, you can rely on uninterrupted monitoring without frequent maintenance or recharging. With its blend of climate sensors, GPS tracking, and long battery life, the Environmental Sensor is the perfect solution for maintaining control and visibility over your environments, whether in a building or on the move.",
    image: "/images/products/environmental.jpg",
    sensors: ['nb-iot', 'gps', 'battery', 'vibration', 'temperature', 'humidity', 'microparticles', 'acceleration'],
    featured: true,
  },
  {
    id: "air-pollution",
    name: "AI Environmental Sensor",
    title: "Smart AI-Powered Climate Monitoring",
    abstract: "Air pollution is responsible for over 4.2 million premature deaths annually, making it one of the most significant environmental health threats globally.",
    description: "Air pollution is responsible for over 4.2 million premature deaths annually, making it one of the most significant environmental health threats globally. ",
    image: "/images/products/environmental.jpg",
    sensors: ['nb-iot', 'gps', 'battery', 'vibration', 'temperature', 'humidity', 'microparticles', 'acceleration'],
    featured: false,
  },
  {
    id: "freight",
    name: "Freight Monitoring",
    title: "Advanced Real-Time Freight Tracking & Monitoring",
    abstract: "Ensure the safety and optimal conditions of your valuable freight with the Freight Monitoring Sensor.",
    description: "Ensure the safety and optimal conditions of your valuable freight with the Freight Monitoring Sensor, an advanced solution designed specifically for logistics and transportation. This IoT device provides continuous monitoring, alarms, and real-time location tracking, ensuring your shipments arrive in perfect condition. The Freight Monitoring Sensor continuously tracks internal conditions within freight containers, including Temperature control (ideal for refrigerated containers) and Humidity monitoring to prevent damage from moisture. This sensor operates using mains voltage with an external power supply, and features a backup battery that lasts for over 8 weeks in case of power loss, ensuring uninterrupted monitoring of your freight. Stay connected to your shipments at all times with the sensor’s built-in GPS tracking and position sensor. Know exactly where your freight is, and enjoy additional control with geofencing capabilities, which send alerts if cargo moves outside designated zones. Equipped with an external antenna, the sensor ensures strong and reliable connectivity, even in challenging environments like refrigerated containers. Receive instant alerts in the event of temperature fluctuations, humidity changes, or power issues, ensuring you can take swift action to prevent potential damage to your goods. Designed for robust freight and container monitoring, this sensor provides the visibility and environmental control you need for secure and successful deliveries, whether you're managing temperature-sensitive goods or high-value shipments.",
    image: "/images/products/container.jpg",
    sensors: ['nb-iot', 'gps', 'battery', 'geofencing', 'temperature', 'humidity', 'position', 'acceleration'],
    featured: true,
  },
  {
    id: "position",
    name: "Position Monitoring",
    title: "Real-Time Situation Alerts & Mesh Networking",
    abstract: "Ensure real-time visibility and control over critical positions and infrastructure.",
    description: "Ensure real-time visibility and control over critical positions and infrastructure with the Position Monitoring Sensor. This advanced IoT device delivers instant notifications when a situation changes, providing seamless monitoring and enhanced connectivity for large-scale operations, such as railways, barriers, and other infrastructure. Stay informed with immediate notifications when important positions shift, such as Open/closed status of barriers, gates, or other access points or changes in key infrastructure that require rapid response. The Position Monitoring Sensor is equipped with Meshnet technology, enabling multiple sensors to operate in a network. This allows all sensors to share data via one central Internet gateway, reducing the need for multiple gateways and simplifying installation across large sites, such as railway yards or industrial areas. With its ability to provide instant status updates and its powerful Meshnet capabilities, the Position Monitoring Sensor is the perfect solution for efficiently monitoring key positions and infrastructure across wide areas, helping you stay ahead of operational changes.",
    image: "/images/products/railroad-barriers.jpg",
    sensors: ['nb-iot', 'meshnet', 'position', 'acceleration', 'battery'],
    featured: true,
  },
  {
    id: "water",
    name: "Water Detector",
    title: "Reliable Water Detection for Critical Infrastructure",
    abstract: "Prevent costly water damage and ensure the safety of sensitive equipment with the Water Detector.",
    description: "The Water Detector Sensor is specifically designed to provide real-time water detection and alerts in environments where water intrusion can lead to severe damage, such as cable ducts and underground infrastructure. With its advanced sensing capabilities and immediate notifications, this device helps prevent costly water damage and ensures the safety of sensitive equipment. The sensor sends instant alarms when water is detected, ensuring that you can take prompt action in the event of flooding, leaks, or water ingress in areas like cable ducts or other critical infrastructure. Once water is no longer present, the sensor sends an all-clear signal, ensuring that the area is secure and water levels are back to normal. Each sensor can monitor several water contact points, allowing you to cover multiple locations or entry points in a single installation. This makes it ideal for large cable ducts or complex underground setups. Designed for tough environments, the Water Detector Sensor is an essential tool for safeguarding cable ducts, tunnels, and other infrastructure against the risks of water damage.",
    image: "/images/products/waterdetector.jpg",
    sensors: ['nb-iot', 'water', 'battery'],
    featured: true,
  },
  {
    id: "power",
    name: "Power Meter",
    title: "Efficient Inductive Current Measurement",
    abstract: "Monitor power usage safely and efficiently with the non-intrusive Power Meter Sensor.",
    description: "The Power Meter Sensor offers precise and non-intrusive current measurement using inductive technology, making it the ideal solution for monitoring power usage in various electrical systems. Designed with convenient measuring clamps, this sensor provides easy installation and accurate readings without needing to disrupt existing electrical setups. The sensor measures electrical current through inductive clamps, allowing for quick and efficient installation without the need for direct contact with wires, making it safer and more convenient to use. This method of current measurement ensures that you can monitor energy consumption or electrical performance without altering or interfering with the electrical circuits. Perfect for a range of applications, from industrial equipment monitoring to building energy management, this sensor provides a reliable solution for tracking power usage across different systems. The Power Meter Sensor is a critical tool for anyone looking to monitor electrical currents safely and efficiently, with the added benefit of simple, non-invasive installation.",
    image: "/images/products/powermeter.jpg",
    sensors: ['nb-iot', 'powerflow', 'battery'],
    featured: true,
  },
  {
    id: "vandalism",
    name: "Vandalism Detector",
    title: "Instant Alerts for Tampering and Intrusions",
    abstract: "Receive immediate notifications of unauthorized access with the Vandalism Detector Sensor.",
    description: "Safeguard your assets and infrastructure with the Vandalism Detector Sensor, designed to detect unauthorized access or tampering. Equipped with a contact sensor and reed switch, this sensor provides immediate alarms whenever it detects signs of vandalism or interference, allowing you to respond quickly and prevent damage. The sensor triggers an immediate alarm when the contact sensor or reed switch is activated, notifying you instantly of any tampering, forced entry, or vandalism attempts. Ideal for protecting critical infrastructure, outdoor equipment, or restricted areas, this sensor ensures that any unauthorized access is quickly detected and reported. Easily integrated into your existing security systems, the Vandalism Detector is a versatile solution for enhancing the protection of sensitive areas or valuable assets. Whether used for industrial sites, public infrastructure, or private properties, the Vandalism Detector Sensor is your first line of defense against tampering and vandalism, ensuring peace of mind and rapid response capabilities.",
    image: "/images/products/vandalism.jpg",
    sensors: ['nb-iot', 'vandalism', 'battery'],
    featured: true,
  },
];

export {
  APP_VERSION,
  LEGAL_IMPRINT,
  LEGAL_TERMS,
  LEGAL_PRIVACY,
  LOGO,
  PRODUCT_LIST,
  SENSOR_LIST,
};
