import { cva } from "class-variance-authority";

import { cn } from "@/lib/utils";

export const buttonVariants = cva(
  cn(
    "inline-flex items-center justify-center whitespace-nowrap",
    "text-md font-medium tracking-tight antialiased",
    "transition-colors",
    "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
    "disabled:pointer-events-none disabled:opacity-50",
  ),
  {
    variants: {
      variant: {
        default: cn(
          "bg-background-secondary text-white border-0.5 border-border-tertiary",
        ),
        icon: cn("bg-transparent text-base-900"),
        inverse: cn(
          "bg-white text-base-700 border-0.5 border-border-secondary",
        ),
        highlight: cn("bg-primary-500 text-white"),
        ghost: cn("bg-transparent text-white"),
        destructive: cn("bg-red-950 text-red-500 border-none"),
      },
      size: {
        default: "h-10 px-4",
        large: "h-12 px-6",
        small: "h-7 px-3 text-xs",
        icon: "h-10 w-10",
      },
      shape: {
        default: "rounded-lg",
        circle: "rounded-full",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      shape: "default",
    },
  },
);
