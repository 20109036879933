import "./App.css";

import { AppRouter } from "./AppRouter";
import {
  UIProvider,
} from "./context";

function App() {
  return (
    <>
      <div className="App bg-background-primary">
        <UIProvider>
          <AppRouter />
        </UIProvider>
      </div>
    </>
  );
}

export default App;
