import React from "react";
import { motion } from "framer-motion";

const sensors = [
  {
    name: "NB-IoT / GSM",
    image: "/images/sensors/gsm.svg",
  },
  {
    name: "GPS",
    image: "/images/sensors/gps.svg",
  },
  {
    name: "Temperature",
    image: "/images/sensors/temperature.svg",
  },
  {
    name: "Humidity",
    image: "/images/sensors/humidity.svg",
  },
  {
    name: "Microparticles",
    image: "/images/sensors/microparticles.svg",
  },
  {
    name: "Acceleration",
    image: "/images/sensors/acceleration.svg",
  },
  {
    name: "Vibration",
    image: "/images/sensors/vibration.svg",
  },
  {
    name: "Self-sufficient",
    image: "/images/sensors/battery.svg",
  },
  {
    name: "Position",
    image: "/images/sensors/position.svg",
  },
  {
    name: "Geofencing",
    image: "/images/sensors/geofencing.svg",
  },
  {
    name: "Meshnet",
    image: "/images/sensors/meshnet.svg",
  },
  {
    name: "Water",
    image: "/images/sensors/water.svg",
  },
  {
    name: "Power Flow",
    image: "/images/sensors/powerflow.svg",
  },
  {
    name: "Vandalism",
    image: "/images/sensors/vandalism.svg",
  }
];

const marqueeVariants = {
  animate: {
    x: [0, -1035],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 50,
        ease: "linear",
      },
    },
  },
};

export const Sensors: React.FC = () => {
  return (
    <>
      <div id="sensors"></div>
      <div className="text-center relative overflow-hidden">
        <p className="font-bold px-8">Our modular platform supports a large number of sensors</p>
        <motion.div
          className="flex flex-row mt-[24px] px-12 items-center justify-center"
          variants={marqueeVariants}
          animate="animate"
        >
          {[...sensors, ...sensors, ...sensors, ...sensors, ...sensors].map((sensor, index) => (
            <div key={`sensor-${index}`} className="flex flex-col min-w-32 space-y-4 md:space-y-0 md:space-x-4 hover:bg-[#F2FAFCFF] rounded-md">
              <img src={sensor.image} alt={sensor.name} className="w-[64px] h-[64px] m-auto" />
              {sensor.name}
            </div>
          ))}
        </motion.div>
      </div>
    </>
  );
};
