import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { buttonVariants } from "./buttonVariants";
import { motion } from "framer-motion";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, shape, asChild = false, ...props }, ref) => {
    if (asChild) {
      return (
        <Slot
          className={cn(buttonVariants({ variant, size, shape, className }))}
          ref={ref}
          {...props}
        />
      );
    } else {
      return (
        // @todo: the interface is not matched with the props
        // @ts-expect-error - the interface is not matched with the props
        <motion.button
          className={cn(buttonVariants({ variant, size, shape, className }))}
          ref={ref}
          whileTap={{ scale: 0.95 }}
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.1 }}
          {...props}
        />
      );
    }
  },
);
Button.displayName = "Button";

export { Button };
