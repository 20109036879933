import React from "react";
import { Intro } from "./sections/01_Intro";
import { Device } from "./sections/02_Device";
import { Sensors } from "./sections/03_Sensors";
import { Products } from "./sections/04_Products";
import { DataHub } from "./sections/05_DataHub";
//import { Concept } from "./sections/06_Concept";
import { Footer } from "./sections/07_Footer";

import "./Home.css";

export const Home: React.FC = () => {
  return (
    <div id="home" className="h-screen">
      <Intro />
      <Device />
      <Sensors />
      <Products />
      <DataHub />
      <Footer />
    </div>
  );
};
