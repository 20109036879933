import React from "react";

interface ComponentProps {
  product: any;
}

export const Intro: React.FC<ComponentProps> = ({ product }) => {
  return (
    <div className="bg-rocknitive w-full h-[60%] md:h-[50%] relative bg-[#1d2128]">
      <div className="max-w-screen-xl m-auto px-[38px] xl:px-0">
        <p className="intro-text text-[32px] leading-[48px] md:text-[64px] md:leading-[84px] text-center md:text-left py-[48px] md:pt-[100px] md:pb-[62px] max-w-[540px]">
          {product.name}
        </p>
      </div>
    </div>
  );
};
