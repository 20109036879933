import React from "react";

export const Intro: React.FC = () => {
  return (
    <div className="bg-rocknitive w-full h-[60%] md:h-[75%] relative bg-[#1d2128]">
        <div className="max-w-screen-xl m-auto px-[38px] xl:px-0">
          <p className="intro-text text-[32px] leading-[48px] md:text-[64px] md:leading-[84px] text-center md:text-left py-[48px] md:pt-[150px] md:pb-[62px]">
            Engineering for<br/>Transparency
          </p>
      </div>
    </div>
  );
};
