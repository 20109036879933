import { NavLink, useLocation } from "react-router-dom";

import { cn } from "@/lib/utils";

import { AnimatedClickable } from "../animatedClickable";
import { Button } from "../ui/button";

interface Link {
  name: string;
  path: string;
}

interface AppHeaderDesktopProps {
  links: Link[];
  className?: string;
}

export const AppHeaderDesktop: React.FC<AppHeaderDesktopProps> = ({
  links,
  className,
}) => {
  const { pathname } = useLocation();
  const scrollTo = (elementId: string) => {
    document.getElementById(elementId.replace('/#/', ''))?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div
      className={cn(
        "sticky top-0 flex w-full flex-col bg-[#1d2128]",
        className,
      )}
      style={{ zIndex: 101 }}
    >
      <div className="flex w-full max-w-screen-xl m-auto flex-row items-center justify-between space-x-4 px-4 py-4">
        <NavLink to="/" className="no-focus cursor-pointer">
          <Logo />
        </NavLink>
        <div className="flex flex-row items-center space-x-8">
          <div className="flex flex-row space-x-4 align-center">
            {pathname === '/' && links.map((link, index) => link.path.startsWith('/#/') ? (
              <AnimatedClickable
                key={index}
                className={cn(
                  "h-fit cursor-pointer items-center justify-center rounded-full px-1 lg:px-4 py-2 text-sm text-[#ffffff]",
                  "border-transparent",
                )}
                onClick={() => scrollTo(link.path)}
              >
                {link.name}
              </AnimatedClickable>
            ) : (
              <AnimatedClickable
                key={index}
                className={cn(
                  "h-fit cursor-pointer items-center justify-center rounded-full px-1 lg:px-4 py-2 text-sm text-[#ffffff]",
                  "border-transparent",
                )}
              >
                <NavLink to={link.path} className="no-focus">
                  {link.name}
                </NavLink>
              </AnimatedClickable>
            ))}
          </div>
        </div>

        <div className="flex flex-row items-center justify-end space-x-4">
            {/*<div className="hidden lg:block text-[#01C6FB]">
              <a href="/#/" target="_blank" rel="noreferrer">
                Login
              </a>
            </div>*/}
            <div>
              <a href="https://calendly.com/rocknitive-info/contact" target="_blank" rel="noreferrer" className="w-full inline-block">
                <Button shape="circle">Contact</Button>
              </a>
            </div>
        </div>
      </div>
    </div>
  );
};

const Logo: React.FC = () => (
  <div className="flex flex-row items-center space-x-3">
    <span className="text-md font-semibold text-[#01C6FB]">
      <img src="/images/rckntvlogo.png" className="w-8 inline-block mr-2" />
      Rocknitive IoT
    </span>
  </div>
);

