import { AnimatePresence, motion } from "framer-motion";

import { useAppHeaderHeight } from "@/hooks";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";

interface Link {
  name: string;
  path: string;
}

interface HeaderProps {
  className?: string;
  links: Link[];
}

export const Header: React.FC<HeaderProps> = ({
  className,
  links,
}) => {
  const { headerRef } = useAppHeaderHeight();

  const scrollTo = (elementId: string) => {
    document.getElementById(elementId.replace('/#/', ''))?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <AnimatePresence>
      <motion.div
        ref={headerRef}
        className={cn(
          "fixed top-0 flex w-full flex-col space-y-2 bg-background-primary pb-2 pt-safe-or-2 h-[75px] bg-[#1d2128]",
          className,
        )}
        style={{ zIndex: 101 }}
      >
        <div className="flex flex-1 flex-row items-center px-6">
        <Logo />

          <div className="flex flex-1 flex-row items-center justify-end">
            <div>
              <a href="https://calendly.com/rocknitive-info/contact" className="w-full inline-block" rel={"noreferrer"} target={"_blank"}>
                <Button shape="circle" className="text-xs">Contact</Button>
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const Logo: React.FC = () => (
  <div className="flex flex-row items-center space-x-3">
    <span className="text-md font-semibold text-[#01C6FB]">
      <img src="/images/rckntvlogo.png" className="w-8 inline-block mr-2" />
      Rocknitive IoT
    </span>
  </div>
);
