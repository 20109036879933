import { useLocation, useNavigate } from "react-router-dom";

export const Imprint = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = (location.state as { from: { pathname: string } }) || {};

  const handleGoBack = () => {
    const { from = { pathname: "/" } } = state;

    if (from === undefined || from.pathname === location.pathname) {
      navigate("/", { replace: true });
      return;
    }

    navigate(from, { replace: true });
  };

  return (
    <div className="flex flex-1 flex-col py-safe-or-4 bg-[#1d2128] text-white">
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex flex-col space-y-2 p-4 text-center">
          <h1 className="font-display text-5xl font-bold">
            Imprint
          </h1>
          <p className="text-md p-4">
            <strong>Information according to § 5 TMG</strong><br />
            ROCKNITIVE GmbH<br />
            Holländerkoppel 19 a<br />
            23858 Reinfeld<br />
            info@rocknitive.com
          </p>
          <p className="text-md p-4">
            <strong>Authorized representative managing directors:</strong><br />
            Mike Lohmann<br />
            Christian Eichinger
          </p>
          <p className="text-md p-4">
            <strong>Local Court:</strong><br />
            Lübeck
          </p>
          <p className="text-md p-4">
            <strong>Registration number:</strong><br />
            HRB 19872 HL
          </p>
          <p className="text-md p-4">
            <strong>Responsible for the content according to §55 Abs. 2 RStV:</strong><br />
            Mike Lohmann
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <button className="w-1/2" onClick={handleGoBack}>
          Back
        </button>
      </div>
    </div>
  );
};
