import React from "react";
import { SectionTitle } from "@/components/ui/sectionTitle";

export const Device: React.FC = () => {
  return (
    <>
      <div id="device"></div>
      <div className="py-[75px] md:py-[120px] text-center p-5 max-w-screen-xl m-auto">
        <p>Rocknitive IoT offers a holistic concept <span className="hidden md:inline">for customized solutions</span></p>
        <SectionTitle className="inline-block mb-[28px]">Rocknitive Device USPs</SectionTitle>

        <div className="flex flex-col md:flex-row text-left space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex flex-col w-full md:w-1/3 bg-[#fddff1] h-[280px] md:h-[320px] rounded-[20px] px-[32px] py-[32px] md:py-[56px] overflow-hidden relative">
            <h2 className="text-[32px] font-bold mb-2">Self-Sufficient</h2>
            <p>
              Communication via mobile radio<br />
              Power supply via rechargeable battery<br />
              Runtime more than 12 months
            </p>
            <div className="absolute right-[-40px] bottom-[-70px]">
              <img src="/images/self-sufficient.svg" alt="Self-Sufficient" className="w-[162px] h-[180px]" />
            </div>
          </div>
          <div className="flex flex-col w-full md:w-2/3 bg-[#d7d4ed] h-[280px] md:h-[320px] rounded-[20px] px-[32px] py-[32px] md:py-[56px] overflow-hidden relative">
            <h2 className="text-[32px] font-bold mb-2">Mobile radio</h2>
            <p>
              NB-IoT & fallback to GSM<br />
              Integrated eSIM<br />
              DTLSv1.2 encrypted communication<br />
              High penetration (e.g. basements or halls)
            </p>
            <div className="absolute right-[-80px] bottom-[-100px] md:right-[32px] md:bottom-[48px]">
              <img src="/images/mobile-radio.svg" alt="Self-Sufficient" className="w-[245px] h-[200px]" />
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row text-left space-y-4 md:space-y-0 md:space-x-4 mt-4">
          <div className="flex flex-col w-full md:w-2/3 bg-[#e2f1db] h-[280px] md:h-[320px] rounded-[20px] px-[32px] py-[32px] md:py-[56px] overflow-hidden relative">
            <h2 className="text-[32px] font-bold mb-2">Touchless Updates</h2>
            <p>
              Over-the-air firmware updates<br />
              Fail-safe update process
            </p>
            <div className="absolute right-[-30px] bottom-[-80px] md:right-[32px] md:bottom-[48px]">
              <img src="/images/updates.svg" alt="Touchless Updates" className="w-[166px] h-[200px]" />
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/3 bg-[#f2fafc] h-[280px] md:h-[320px] rounded-[20px] px-[32px] py-[32px] md:py-[56px] overflow-hidden relative">
            <h2 className="text-[32px] font-bold mb-2">Expandable</h2>
            <p>
              Interfaces for analog & digital sensors<br />
              Customization possible
            </p>
            <div className="absolute right-[-5px] bottom-[-80px]">
              <img src="/images/expandable.svg" alt="Expandable" className="w-[160px] h-[180px]" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
