import { SectionTitle } from "@/components/ui/sectionTitle";
import React, { useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { PRODUCT_LIST } from "@/constants";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const products = PRODUCT_LIST;

export const Products: React.FC = () => {
  const sliderRef = useRef<Slider>(null);
  const navigate = useNavigate();

  const handleNavigate = (pathname: string) => {
    navigate(pathname, { replace: true });
  };

  const carouselSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    dotsClass: "products-dots",
    infinite: true,
    speed: 1200,
    variableWidth: true,
    slidesToScroll: 1,
    swipe: false,
  };

  return (
    <>
      <div id="products"></div>
      <div className="bg-[#FAFAFB] mt-[72px] md:mt-[120px]">
        <div className="flex flex-col md:flex-row py-[72px] xl:px-0 m-auto">
          <div className="w-full md:w-1/3 md:ml-32 text-center md:text-left">
            <SectionTitle className="inline-block mb-[28px]">Products</SectionTitle>
            <div className="max-w-[462px] text-[18px] md:text-[24px]">
              <p className="px-8 md:px-0 md:pr-16">
                A selection of IoT products that we have in use.
              </p>
            </div>
            <div className="hidden md:flex justify-end space-x-4 pr-8 mt-24">
              <a 
                onClick={() => sliderRef.current?.slickPrev()}
                className="h-[52px] w-[52px] leading-[50px] text-center rounded-full border material-symbols-outlined cursor-pointer"
              >
                arrow_back_ios_new
              </a>
              <a 
                onClick={() => sliderRef.current?.slickNext()} 
                className="h-[52px] w-[52px] leading-[50px] text-center rounded-full border material-symbols-outlined cursor-pointer"
              >
                arrow_forward_ios
              </a>
            </div>
          </div>
          <div className="w-full md:w-2/3">
            <Slider 
              {...carouselSettings}
              className="ml-4"
              ref={sliderRef}
            >
              {products
                .filter((product) => product.featured)
                .map((product, index) => (
                <NavLink key={`product-${index}`} to={`/product/${product.id}`} className="no-focus">
                  <div
                    className="flex flex-col max-w-[300px] bg-white mx-2 rounded-[20px] shadow-lg ml-4 my-8 hover:scale-105"
                  >
                    <img src={product.image} alt={product.name} className="w-full object-cover mb-[8px] md:mb-[14px] h-[175px] rounded-t-[20px]" />
                    <h3 className="w-full px-[16px] font-bold">{product.name}</h3>
                    <p className="w-full h-[64px] px-[16px] mt-2 mb-8">{product.abstract || product.description}</p>
                  </div>
                </NavLink>
                
              ))}
            </Slider>
          </div>
          <div className="md:hidden flex justify-center space-x-4">
            <a 
              onClick={() => sliderRef.current?.slickPrev()}
              className="h-[52px] w-[52px] leading-[50px] text-center rounded-full border material-symbols-outlined cursor-pointer"
            >
              arrow_back_ios_new
            </a>
            <a 
              onClick={() => sliderRef.current?.slickNext()} 
              className="h-[52px] w-[52px] leading-[50px] text-center rounded-full border material-symbols-outlined cursor-pointer"
            >
              arrow_forward_ios
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
