import React from "react";
import {
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from "react-router-dom";

import {
  Home,
  LayoutApp,
  PageError,
  PageNotFound,
  Imprint,
  PrivacyPolicy,
  ProductDetails,
} from "./routes";

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutApp />} errorElement={<PageError />}>
      {/* Make Home the index route and accessible via /home */}
      <Route index element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="imprint" element={<Imprint />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="product/:product" element={<ProductDetails />} />
      <Route path="solutions/:product" element={<ProductDetails />} />

      {/* Fallback for any unmatched routes */}
      <Route path="*" element={<PageNotFound />} />
    </Route>,
  ),
);

export const AppRouter: React.FC = () => {
  return <RouterProvider router={router} />;
};
