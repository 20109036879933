import { Button } from "@/components";
import { SectionTitle } from "@/components/ui/sectionTitle";
import React from "react";

export const DataHub: React.FC = () => {
  return (
    <>
      <div id="datahub"></div>
      <div className="pt-[75px] md:py-[120px] max-w-screen-xl m-auto">
        <SectionTitle className="mb-[18px]">Rocknitive DataHub</SectionTitle>
        <div className="flex flex-col md:flex-row mt-[40px] p-5">
          <div className="flex flex-1 flex-col p-4">
            <span className="material-symbols-outlined text-[#379AE6FF] w-[48px] h-[48px] text-5xl mb-4">
              settings
            </span>
            <h2 className="text-[24px] font-bold">Device Management & Configuration</h2>
            <p className="mt-2 leading-[30px] pr-12">
              Automatic onboarding<br />
              Individual parameterization<br />
              Customization in live operation
            </p>
          </div>
          <div className="flex flex-1 flex-col p-4">
            <span className="material-symbols-outlined text-[#379AE6FF] w-[48px] h-[48px] text-5xl mb-4">
              language
            </span>
            <h2 className="text-[24px] font-bold">Communication</h2>
            <p className="mt-2 leading-[30px] pr-12">
              Encrypted data connection between devices and the server<br />
              Over-the-air updates are deployed via the server (Rollout possible in stages)
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row mt-[40px] p-5">
          <div className="flex flex-1 flex-col p-4">
            <span className="material-symbols-outlined text-[#379AE6FF] w-[48px] h-[48px] text-5xl mb-4">
              share
            </span>
            <h2 className="text-[24px] font-bold">Data Forwarding</h2>
            <p className="mt-2 leading-[30px] pr-12">
              Optionally, sensor data can be forwarded to other systems in real time: HTTP, MQTT, AWS SNS, etc.<br />
              Export to CSV/Excel
            </p>
          </div>
          <div className="flex flex-1 flex-col p-4">
            <span className="material-symbols-outlined text-[#379AE6FF] w-[48px] h-[48px] text-5xl mb-4">
              monitoring
            </span>
            <h2 className="text-[24px] font-bold">Advanced Analytics</h2>
            <p className="mt-2 leading-[30px] pr-12">
              Data preparation <br />
              Visual rules<br />
              Alerts<br />
              Monitoring through AI<br />
              Visualization in the dashboard
            </p>
          </div>
        </div>

        <div className="mt-[48px] md:mb-[144px] md:mt-[120px] bg-[#F2FAFCFF] py-[48px] px-[38px] md:px-[70px] xl:rounded-[8px]">
          <div className="flex flex-col md:flex-row">
            <SectionTitle className="flex flex-1 text-left mb-[18px] text-[32px]">We build your project with passion.</SectionTitle>
            <p className="flex flex-1 text-[20px] leading-[40px] mt-2">
              You have the idea, we have experience with software engineering, IoT, the technical resources and a solid technical basis to implement your project.
            </p>
          </div>
          <div className="mt-[32px] text-center">
            <a href="https://calendly.com/rocknitive-info/contact" target={"_blank"} rel={"noreferrer"}>
              <Button>Arrange Appointment</Button>
            </a>
          </div>
        </div>
      </div>
    </>
    
  );
};
