import { useLocation, useNavigate } from "react-router-dom";

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = (location.state as { from: { pathname: string } }) || {};

  const handleGoBack = () => {
    const { from = { pathname: "/" } } = state;

    if (from === undefined || from.pathname === location.pathname) {
      navigate("/", { replace: true });
      return;
    }

    navigate(from, { replace: true });
  };

  return (
    <div className="flex flex-1 flex-col py-safe-or-4 bg-[#1d2128] text-white">
      <h1 className="font-display text-5xl font-bold text-center pt-32 text-[#01C6FB]">
        Privacy Policy
      </h1>
      <div className="flex flex-col md:flex-row md:space-x-8 space-y-2 p-4 max-w-screen-xl mx-auto">
        <div className="flex flex-1 flex-col">
          <h2 className="font-bold text-2xl text-[#01C6FB]">Datenschutzerklärung</h2>
          <p className="text-md py-4">
            Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) im Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Verantwortlicher</h3>
          <p className="text-md py-4">
            ROCKNITIVE GmbH<br />
            Holländerkoppel 19 a<br />
            23858 Reinfeld<br />
            Deutschland
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">E-Mailadresse:</h3>
          <p className="text-md py-4">
            mike@rocknitive.com
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Geschäftsführer:</h3>
          <p className="text-md py-4">
            Mike Lohmann, Christian Eichinger
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Link zum Impressum:</h3>
          <p className="text-md py-4">
            https://www.rocknitive.com/imprint
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Kontakt Datenschutzbeauftragter:</h3>
          <p className="text-md py-4">
            Mike Lohmann (mike@rocknitive.com)
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Arten der verarbeiteten Daten:</h3>
          <p className="text-md py-4">
            <ul>
              <li>Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen)</li>
              <li>Kontaktdaten (z.B., E-Mail, Telefonnummern)</li>
              <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos)</li>
              <li>Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten)</li>
              <li>Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen)</li>
            </ul>
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Kategorien betroffener Personen:</h3>
          <p className="text-md py-4">
            Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Zweck der Verarbeitung:</h3>
          <p className="text-md py-4">
            <ul>
              <li>Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte</li>
              <li>Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern</li>
              <li>Sicherheitsmaßnahmen</li>
              <li>Reichweitenmessung/Marketing</li>
            </ul>
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Verwendete Begrifflichkeiten:</h3>
          <p className="text-md py-4">
            „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.<br /><br />
            „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.<br /><br />
            „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.<br /><br />
            „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.<br /><br />
            Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.<br /><br />
            „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
          </p>
        </div>
        <div className="flex flex-1 flex-col">
          <h3 className="font-bold text-lg text-[#01C6FB]">Maßgebliche Rechtsgrundlagen</h3>
          <p className="text-md py-4">
            Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich der Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, Folgendes:<br /><br />
            Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;<br /><br />
            Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;<br /><br />
            Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;<br /><br />
            Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.<br /><br />
            Die Rechtsgrundlage für die erforderliche Verarbeitung zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO.<br /><br />
            Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO.<br /><br />
            Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie ehoben wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4 DSGVO.<br /><br />
            Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO.
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Sicherheitsmaßnahmen</h3>
          <p className="text-md py-4">
            Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.<br /><br />
            Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
          </p>
          <h3 className="font-bold text-lg text-[#01C6FB]">Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und Dritten</h3>
          <p className="text-md py-4">
            Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern, gemeinsam Verantwortlichen oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserfüllung erforderlich ist), Nutzer eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).<br /><br />
            Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe offenbaren, übermitteln oder ihnen sonst den Zugriff gewähren, erfolgt dies insbesondere zu administrativen Zwecken als berechtigtes Interesse und darüberhinausgehend auf einer den gesetzlichen Vorgaben entsprechenden Grundlage.
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <button className="w-1/2" onClick={handleGoBack}>
          Back
        </button>
      </div>
    </div>
  );
};
