import { Button } from "@/components";
import React from "react";

interface ComponentProps {
  product: any;
}

export const Footer: React.FC<ComponentProps> = ({ product }) => {
  const scrollTo = (elementId: string) => {
    document.getElementById(elementId.replace('/#/', ''))?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="bg-[#1E2128CC] py-[24px] md:py-[56px]">
      <div className="flex flex-col max-w-screen-xl m-auto px-[38px] xl:px-0 text-white">
        <img src="/images/rckntvlogo.png" alt="Rocknitive Logo" className="w-[70px] md:w-[135px] md:mt-[-160px]" />
        <div className="flex flex-col md:flex-row justify-between w-full">
          <div className="w-32" />
          <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-8 text-[16px] font-bold mb-8 mt-8 md:mt-0">
            <p onClick={() => scrollTo('sensor')} className="cursor-pointer">{product.name}</p>
            <p onClick={() => scrollTo('highlights')} className="cursor-pointer">Highlights</p>
            <p onClick={() => scrollTo('datahub')} className="cursor-pointer">Data Processing</p>
          </div>
          <div className="space-x-4 w-32">
            <a href="mailto:info@rocknitive.com">
              <Button shape="circle">Get in Touch</Button>
            </a>
          </div>
        </div>
        <div className="w-full">
          <div className="pt-8 md:pt-4 text-center">
            © 2024 Rocknitive GmbH • <a href="/#/privacy-policy" target="_blank" rel="noreferrer">Privacy</a> • <a href="/#/imprint" target="_blank" rel="noreferrer">Imprint</a>
          </div>
        </div>
      </div>
    </div>
  );
};
