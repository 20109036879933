import React from "react";
import { useParams } from "react-router-dom";
import { Intro } from "./sections/01_Intro";
import { Product } from "./sections/02_Product";
import { Highlights } from "./sections/03_Highlights";
import { DataHub } from "./sections/04_DataHub";
import { Footer } from "./sections/05_Footer";
import { PRODUCT_LIST } from "@/constants";

import "./ProductDetails.css";

export const ProductDetails: React.FC = () => {
  const { product } = useParams();

  const productDetails = PRODUCT_LIST.find((p) => p.id === product);

  if (!productDetails) {
    return null;
  }

  return (
    <>
      <div id={`product-${productDetails.id}`} className="h-screen">
        <Intro product={productDetails} />
        <Product product={productDetails} />
        <Highlights product={productDetails} />
        <DataHub />
        <Footer product={productDetails} />
      </div>
    </>
  );
};
